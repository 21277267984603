import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterLink } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { take } from 'rxjs';
import { country } from '../../../../v3/features/country/data';
import { GetPreOrderCommercialCategoriesUseCase } from '../../../core/usecases/bulk-preorder/get-pre-order-commercial-categories.usecase';
import { GetCategoryHierarchyUseCase } from '../../../core/usecases/get-category-hierarchy-usecase';
import { ListenToProductsPageExperienceChangesUseCase } from '../../../core/usecases/products/listen-to-products-page-experience-change.usecase';
import { ShouldDisplayNewHomePageUseCase } from '../../../core/usecases/products/should-display-new-home-page.usecase';
import { BaseComponent } from '../../base/base.component';
import { EventWithType } from '../../base/base.presenter';
import { LastCategorySalesReportCardComponent } from '../../last-category/last-category-sales-report-card/last-category-sales-report-card.component';
import { CategoriesSidebarComponent } from '../../products/category-products/categories-sidebar/categories-sidebar.component';
import { LevelOneCategoriesSidebarComponent } from '../../products/category-products/level-one-categories-sidebar/level-one-categories-sidebar.component';
import { SubcategoryFilterComponent } from '../../products/category-products/subcategory-filter/subcategory-filter.component';
import { ProductCardComponent } from '../../products/products-v2/product-card/product-card.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { PAGES_FILTER_SIZE } from '../../shared/constants';
import {
  CATEGORY_PRODUCTS_SORTING_OPTIONS,
  DEFAULT_SORTING_OPTION,
} from '../../shared/constants/category-products';
import { COMMERCIAL_CATEGORY_TREE_ROOT } from '../../shared/constants/commercial-categories';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';
import {
  CommercialCategoryTree,
  CommercialCategoryTreeNode,
} from '../../shared/interfaces/commercial-categories.interface';
import { CategoryInterface } from '../../shared/interfaces/product.interafce';
import { ResponsiveService } from '../../shared/services/responsive.service';
import {
  PreOrderProductsPresenter,
  PreOrderProductsSideEffect,
  PreOrderProductsViewState,
} from './pre-order-products.presenter';

@Component({
  selector: 'app-pre-order-products',
  templateUrl: './pre-order-products.component.html',
  styleUrls: ['./pre-order-products.component.scss'],
  standalone: true,
  providers: [PreOrderProductsPresenter],
  imports: [
    NgIf,
    NgFor,
    LoaderComponent,
    NgClass,
    ProductCardComponent,
    PaginationModule,
    SubcategoryFilterComponent,
    CollapseModule,
    HoverStyleDirective,
    RouterLink,
    BsDropdownModule,
    MatCheckboxModule,
    LevelOneCategoriesSidebarComponent,
    CategoriesSidebarComponent,
    LastCategorySalesReportCardComponent,
    FormsModule,
  ],
})
export class PreOrderProductsComponent extends BaseComponent<
  PreOrderProductsPresenter,
  PreOrderProductsViewState,
  EventWithType,
  PreOrderProductsSideEffect
> {
  public presenter: PreOrderProductsPresenter = inject(PreOrderProductsPresenter);

  @ViewChild('categoriesSidebar') categoriesSidebar: CategoriesSidebarComponent;

  public levelOneCategoriesNodes: CommercialCategoryTreeNode[];

  public sortingOptions = CATEGORY_PRODUCTS_SORTING_OPTIONS;

  public pagesFilterSize = PAGES_FILTER_SIZE;

  public isMobile: boolean;

  public iconsBaseUrl: string;

  public isDiscoverabilityFunnelEnabled = false;

  public fourthLevelCategoriesList: any;

  public selectedCategoryName: any;

  public pageTitle: string = this.trans('pre-order.exclusiveProducts');

  public isCategoriesLoaded = false;

  public selectedCategoryId = '';

  public categories: CategoryInterface[];

  public categoryHierachy: CommercialCategoryTreeNode[];

  public categoryHierachyHasFourlevels = false;

  public categoryHierarchyHasLessThanTwoLevels = false;

  public selectedSortBy = DEFAULT_SORTING_OPTION;

  constructor(
    private responsiveService: ResponsiveService,
    private _getCategoryHierarchyUseCase: GetCategoryHierarchyUseCase,
    private _shouldDisplayNewHomePageUseCase: ShouldDisplayNewHomePageUseCase,
    private _listenToProductsPageExperienceChanges: ListenToProductsPageExperienceChangesUseCase,
    private _getPreOrderCommercialCategoriesUseCase: GetPreOrderCommercialCategoriesUseCase,
  ) {
    super();
  }

  public onInit(): void {
    this.getMobileStatus();
    this.getSelectedCommercialCategories();
    this.checkUserEligibilityForDiscoverabilityFunnel();
    this.presenter.fetch();
  }

  changeItemsOnPage(limit: number): void {
    this.presenter.limit(limit);
  }

  changeSorting(sortBy: string): void {
    this.presenter.sortBy(sortBy);

    this.selectedSortBy = CATEGORY_PRODUCTS_SORTING_OPTIONS.find(
      (option) => option.value === sortBy,
    )!;
  }

  checkUserEligibilityForDiscoverabilityFunnel(): void {
    this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    this._listenToProductsPageExperienceChanges.execute().subscribe(() => {
      this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    });
  }

  getMobileStatus(): void {
    this.responsiveService.getMobileStatus().subscribe({
      next: (res) => {
        this.isMobile = res;
      },
    });
  }

  getSelectedCommercialCategories(emittedCategoryId?: { id: string }): void {
    if (emittedCategoryId) {
      this.presenter.filterByCategory(emittedCategoryId.id);
    }

    this._getPreOrderCommercialCategoriesUseCase
      .execute(country.code)
      .pipe(take(1))
      .subscribe({
        next: (tree) => {
          this.setUpCategories(tree, emittedCategoryId);
        },
      });
  }

  setCategoriesIcons(): void {
    const categoryEnglishNames = this.levelOneCategoriesNodes.map((categoryNode) => ({
      name: categoryNode.value.name.englishName,
    }));
    this.isCategoriesLoaded = true;
  }

  setUpCategories(tree: CommercialCategoryTree, params: any): void {
    if (tree) {
      if (params?.id) {
        this.selectedCategoryId = params.id;
        const data = {
          countryCode: country.code,
          categoryId: params.id,
        };

        this._getCategoryHierarchyUseCase.execute(data).subscribe((categoryHierarchy) => {
          if (categoryHierarchy.length) {
            this.categoryHierachy = categoryHierarchy;
            this.categoryHierachyHasFourlevels =
              categoryHierarchy.length > 3 &&
              categoryHierarchy[categoryHierarchy.length - 4].hasChildren;
            this.categoryHierarchyHasLessThanTwoLevels = false;

            if (categoryHierarchy.length < 3 && !categoryHierarchy[0].hasChildren) {
              this.categoryHierarchyHasLessThanTwoLevels = true;
              const firstLevelCategoryName = COMMERCIAL_CATEGORY_TREE_ROOT.name;
              this.pageTitle = this.localizedValue(firstLevelCategoryName);
              this.levelOneCategoriesNodes = tree.root.children;
              this.isCategoriesLoaded = true;
            }

            if (this.categoryHierachyHasFourlevels) {
              this.fourthLevelCategoriesList =
                categoryHierarchy[categoryHierarchy.length - 4].children;
              this.categoryHierarchyHasLessThanTwoLevels = false;
            }

            if (this.isMobile) {
              this.levelOneCategoriesNodes = tree.root.children;
            }

            this.selectedCategoryName = categoryHierarchy[0].value.name;

            this.categoriesSidebar?.getSelectedCategoryNodes(categoryHierarchy);

            this.pageTitle = this.localizedValue(this.selectedCategoryName);

            this.isCategoriesLoaded = true;
          }
        });
      } else {
        this.selectedCategoryName = COMMERCIAL_CATEGORY_TREE_ROOT.name;
        this.categoryHierarchyHasLessThanTwoLevels = true;
        this.levelOneCategoriesNodes = tree.root.children;
        this.isCategoriesLoaded = true;
      }
    }
  }
}
